<template>
  <div>
    <div class="main-title">课程</div>
    <div class="content-list-page page-content">
      <div class="list-head-box">
        <div data-v-69874ad0="" class="list-head">
          <p data-v-69874ad0="" class="list-head-title">温馨提示</p>
          <ul data-v-69874ad0="">
            <li data-v-69874ad0="">封面大小：960*720</li>
          </ul>
        </div>
      </div>

      <div class="padding-t-sm padding-b-sm">
        <span class="fl-l">
          <el-select v-model="grade" clearable placeholder="年级" size="small">
            <el-option
              v-for="item in grades"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </span>

        <span class="margin-l-sm fl-l">
          <el-select v-model="type" clearable placeholder="类型" size="small">
            <el-option
              v-for="item in types"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </span>

        <span class="margin-l-sm fl-l">
          <el-select
            v-model="theme"
            clearable
            placeholder="选择主题"
            size="small"
          >
            <el-option
              v-for="item in themes"
              :key="item.id"
              :label="item.name"
              :value="item.name"
            >
            </el-option>
          </el-select>
        </span>

        <span class="margin-l-sm fl-l">
          <el-input
            v-model="name"
            placeholder="课程名称"
            clearable
            size="small"
          ></el-input>
        </span>

        <!--                <span class="margin-l-sm fl-l" >-->
        <!--                    <el-date-picker-->
        <!--                            size="small"-->
        <!--                            v-model="value2"-->
        <!--                            type="daterange"-->
        <!--                            align="right"-->
        <!--                            unlink-panels-->
        <!--                            range-separator="至"-->
        <!--                            start-placeholder="开始日期"-->
        <!--                            end-placeholder="结束日期"-->
        <!--                            :picker-options="pickerOptions">-->
        <!--                    </el-date-picker>-->
        <!--                </span>-->

        <span class="margin-l-sm fl-l">
          <el-button
            type="primary"
            icon="el-icon-search"
            size="small"
            @click="search"
            >搜索</el-button
          >
        </span>

        <span class="fl-r">
          <router-link to="/teacherCourse/add" class="router-link">
            <el-button type="primary" size="small">新建课程</el-button>
          </router-link>
        </span>

        <div style="clear: both"></div>
      </div>

      <div class="table-content">
        <el-table
          :header-cell-style="{ padding: 0 }"
          :cell-class-name="rowItem"
          ref="singleTable"
          :data="tableData"
          highlight-current-row
          @current-change="handleCurrentChange"
          v-loading="loading"
          style="width: 100%; background: #fff"
        >
          <el-table-column label="ID" property="id" width="80">
          </el-table-column>
          <el-table-column label="图片" width="200">
            <template slot-scope="scope">
              <el-image
                class="table-item-img"
                :src="imageUrl + scope.row.pic"
                :preview-src-list="[imageUrl + scope.row.pic]"
              >
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
            </template>
          </el-table-column>
          <el-table-column property="index" label="名称">
            <template slot-scope="scope">
              <span>{{ scope.row.name }}</span>
            </template>
          </el-table-column>

          <el-table-column property="created_at" label="创建时间">
          </el-table-column>

          <el-table-column property="index" label="年级">
            <template slot-scope="scope">
              <span>{{ scope.row.grade }}</span>
            </template>
          </el-table-column>
          <el-table-column property="index" label="类型">
            <template slot-scope="scope">
              <span>{{ scope.row.type }}</span>
            </template>
          </el-table-column>
          <el-table-column property="index" label="主题">
            <template slot-scope="scope">
              <span>{{ scope.row.theme }}</span>
            </template>
          </el-table-column>
          <el-table-column property="index" label="创建人">
            <template slot-scope="scope">
              <span>{{ scope.row.user_name }}</span>
            </template>
          </el-table-column>
          <el-table-column property="address" label="操作">
            <template slot-scope="scope">
              <span
                ><el-link
                  slot="reference"
                  type="primary"
                  @click="editDetail(scope.row)"
                  >编辑</el-link
                ></span
              >
              -
              <span
                ><el-link
                  slot="reference"
                  type="primary"
                  @click="courseDetail(scope.row)"
                  >详情</el-link
                ></span
              >
              -
              <span>
                <el-popconfirm
                  icon="el-icon-info"
                  icon-color="red"
                  title="删除后将不可恢复，确定删除吗？"
                  @confirm="deleted(scope.row)"
                >
                  <el-link slot="reference" type="primary">删除</el-link>
                </el-popconfirm>
              </span>
            </template>
          </el-table-column>
        </el-table>
      </div>

      <div class="table-batch">
        <span class="fl-r">
          <el-pagination
            small
            background
            :current-page="page"
            :page-sizes="[10, 20]"
            :page-size="pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="setPageSize"
            @current-change="setPage"
            :total="total"
          >
          </el-pagination>
        </span>
        <div style="clear: both"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "template-list",
  data() {
    return {
      grades: [],
      grade: "",
      name: "",
      types: [],
      type: "",
      themes: [],
      theme: "",
      page: 1,
      pageSize: 10,
      total: 0,
      loading: false,
      imageUrl: config.imageUrl,
      options: [
        {
          value: "选项1",
          label: "黄金糕",
        },
      ],
      value: "",
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      value2: "",
      tableData: [],
      currentRow: null,
    };
  },
  methods: {
    ...mapActions("teacherCourse", [
      "getTeacherCourseList",
      "delTeacherCourse",
    ]),
    ...mapActions("common", ["delete", "getBaseData"]),
    setCurrent(row) {
      this.$refs.singleTable.setCurrentRow(row);
    },
    handleCurrentChange(val) {
      this.currentRow = val;
    },
    headeCellStyle({ row, rowIndex }) {
      return "{padding:0}";
    },
    rowItem() {
      return "row-item";
    },
    search() {
      this.tableData = [];
      this.page = 1;
      this.getList();
    },
    getList() {
      this.loading = true;
      let data = {
        page: this.page,
        pageSize: this.pageSize,
        type: this.type,
        grade: this.grade,
        theme: this.theme,
        name: this.name,
      };
      this.getTeacherCourseList(data).then((res) => {
        this.loading = false;
        this.tableData = res.data.list;
        this.total = res.data.total;
      });
    },
    setStatus(row, rowIndex) {
      let _this = this;
      let data = {
        id: row.id,
        status: !row.status,
      };

      this.loading = true;
      this.setCourseStatus(data).then(
        (success) => {
          _this.tableData[rowIndex].status = success.data.status;

          _this.$message({
            message: "设置成功",
            type: "success",
          });
          this.loading = false;
        },
        (error) => {
          _this.$message({
            message: "设置失败",
            type: "error",
          });
          this.loading = false;
        }
      );
    },
    deleted(row, rowIndex) {
      let _this = this;
      this.loading = true;
      //   let data = {
      //     id: row.id,
      //     model: "TeacherCourse",
      //   };
      this.delTeacherCourse(row.id).then((res) => {
        _this.getList();
        if (res.res_info == "ok") {
          _this.$message({
            message: "删除成功",
            type: "success",
          });
        }
      });
      // this.delete(data).then(success => {
      //     _this.getList()
      //     _this.$message({
      //         message: '删除成功',
      //         type: 'success'
      //     });
      // },error => {
      //     _this.$message({
      //         message: '删除失败',
      //         type: 'error'
      //     });
      // })
    },
    setPageSize(pageSize) {
      this.pageSize = pageSize;
      this.getList();
    },
    setPage(page) {
      this.page = page;
      this.getList();
    },
    courseDetail(row) {
      this.$router.push("/teacherCourse/detail/" + row.id + "/manage");
    },
    editDetail(row) {
      this.$router.push("/teacherCourse/edit/" + row.id);
    },
  },
  async mounted() {
    this.getList();
    let baseData = await this.getBaseData();

    baseData.data.teacherCourseConfig.forEach((item) => {
      switch (item.type) {
        case 1:
          this.grades.push(item);
          break;
        case 2:
          this.types.push(item);
          break;
        case 3:
          this.themes.push(item);
          break;
      }
    });
  },
};
</script>

<style></style>
